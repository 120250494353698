import React from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { HStack } from "@chakra-ui/react";
import {
  cleanLangFromPathname,
  UDINI_HOME_ROUTE,
  UDINI_NEWS_ROUTE,
  UDINI_PRODUCTS_SMILE_ROUTE,
} from "src/utils/constants/routes";
import { HeaderTextLink } from "../../HeaderTextLink";

export const Nav = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const pathname = cleanLangFromPathname(router.pathname);
  return (
    <HStack alignItems="center" spacing={"65px"}>
      <HeaderTextLink href={UDINI_HOME_ROUTE} accented={pathname === UDINI_HOME_ROUTE}>
        {t("shared:linkHome")}
      </HeaderTextLink>
      <HeaderTextLink href={UDINI_HOME_ROUTE + "#tech"}>{t("shared:linkAiaas")}</HeaderTextLink>
      <HeaderTextLink href={UDINI_PRODUCTS_SMILE_ROUTE} accented={pathname === UDINI_PRODUCTS_SMILE_ROUTE}>
        {t("shared:linkProductsSmile")}
      </HeaderTextLink>
      <HeaderTextLink href={UDINI_NEWS_ROUTE} accented={pathname === UDINI_NEWS_ROUTE}>
        {t("shared:linkNews")}
      </HeaderTextLink>
    </HStack>
  );
};
